import { change, initialize } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { PREFS_CONFIG_SETTINGS } from "./printingSettings.constants";
import { PRINTING_SETTING_FORM } from "../../constants/forms";
import { getCurrentUser, updateUserData } from "../user/user.actions";
import {
  mapPrintingSettings,
  preparePrintingSettings,
} from "./printingSettings.model";
import { printerService } from "../app/app.service";
import { isElectronEnvironment } from "../../utils/checkUserAgent";

const ACTION_NAMESPACE = "PRINTING_SETTING_FORM";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  INITIALIZE_FORM: createAsyncActionTypes("INITIALIZE_FORM"),
  UPDATE_PRINTING_SETTINGS: createAsyncActionTypes("UPDATE_PRINTING_SETTINGS"),
});

export const changePrintingSettingsFieldValue = (fieldName, value) =>
  change(PRINTING_SETTING_FORM, fieldName, value);

export const initializeForm = createAsyncAction(
  () => async dispatch => {
    const user = await dispatch(getCurrentUser());
    const printers = isElectronEnvironment()
      ? printerService.listPrinters()
      : [];

    dispatch(
      initialize(PRINTING_SETTING_FORM, mapPrintingSettings(user, printers))
    );
  },
  ACTIONS.INITIALIZE_FORM
);

export const savePrintingSettings = createAsyncAction(
  formValues => async dispatch => {
    const values = preparePrintingSettings(formValues);
    const currentUser = await dispatch(getCurrentUser());
    return dispatch(
      updateUserData(currentUser.uid, {
        [PREFS_CONFIG_SETTINGS]: {
          ...currentUser[PREFS_CONFIG_SETTINGS],
          ...values,
        },
      })
    );
  },
  ACTIONS.UPDATE_PRINTING_SETTINGS
);
