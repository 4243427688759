import get from "lodash/get";

import {
  LABELS_PER_PAGE_FIELD,
  MISSING_ASSET_NUMBER,
  MISSING_ASSET_NUMBER_FIELD,
  PRINT_TO_THERMAL_FIELD,
  THERMAL_PRINTER_ASSET_NUMBER_FIELD,
  THERMAL_PRINTER_NAME_FIELD,
  THERMAL_PRINTER_TYPE_FIELD,
  THERMAL_PRINTER_TYPES,
} from "./printingSettings.constants";

export const defaultThermalPrinterInitialValues = {
  [THERMAL_PRINTER_TYPE_FIELD]: THERMAL_PRINTER_TYPES[0].value,
  [THERMAL_PRINTER_NAME_FIELD]: "",
  [THERMAL_PRINTER_ASSET_NUMBER_FIELD]: "",
  [MISSING_ASSET_NUMBER_FIELD]: false,
};

export const mapPrintingSettings = ({ prefsConfigSettings }, printers) => {
  const configPrinterName = get(
    prefsConfigSettings,
    THERMAL_PRINTER_NAME_FIELD,
    ""
  );

  return {
    [LABELS_PER_PAGE_FIELD]: String(
      get(prefsConfigSettings, LABELS_PER_PAGE_FIELD, "1")
    ),
    [PRINT_TO_THERMAL_FIELD]: get(
      prefsConfigSettings,
      PRINT_TO_THERMAL_FIELD,
      false
    ),
    [THERMAL_PRINTER_TYPE_FIELD]: get(
      prefsConfigSettings,
      THERMAL_PRINTER_TYPE_FIELD,
      defaultThermalPrinterInitialValues[THERMAL_PRINTER_TYPE_FIELD]
    ),
    [THERMAL_PRINTER_NAME_FIELD]: configPrinterName,
    [THERMAL_PRINTER_ASSET_NUMBER_FIELD]: get(
      prefsConfigSettings,
      THERMAL_PRINTER_ASSET_NUMBER_FIELD,
      defaultThermalPrinterInitialValues[THERMAL_PRINTER_ASSET_NUMBER_FIELD]
    ),
    [MISSING_ASSET_NUMBER_FIELD]: isDefaultPopulatedAssetNumberField(
      get(
        prefsConfigSettings,
        THERMAL_PRINTER_ASSET_NUMBER_FIELD,
        defaultThermalPrinterInitialValues[MISSING_ASSET_NUMBER_FIELD]
      )
    ),
  };
};

const isDefaultPopulatedAssetNumberField = assetNumber =>
  assetNumber === MISSING_ASSET_NUMBER;

export const preparePrintingSettings = formValues => {
  const { labelsPerPage, missingAssetNumber, ...values } = formValues;
  return {
    ...values,
    [LABELS_PER_PAGE_FIELD]: Number(labelsPerPage),
  };
};
