import React from "react";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import {
  DEFAULT_BULK_REVERSE_IT_REFERENCE,
  DOMESTIC_SHIPPING_DEFAULTS,
  NUMBER_OF_ITEMS,
  PLEASE_SELECT,
  PRODUCT,
  REFERENCE_1_PREFIX,
  SENDERS_ADDRESS_ON_LABEL,
  SERVICE,
  WEIGHT_KG,
} from "../../../constants/strings";
import {
  BULK_REVERSE_REF_FIELD,
  DOM_ADDRESS_ON_LABEL_FIELD,
  DOM_NUM_ITEMS_FIELD,
  DOM_PRODUCT_FIELD,
  DOM_REFERENCE_TEXT_FIELD,
  DOM_SERVICE_FIELD,
  DOM_WEIGHT_FIELD,
} from "../../../features/shippingSettings/shippingSettings.constants";
import SubTitle from "../../../components/Title/SubTitle";
import CheckBoxInput from "../../../components/CheckBoxInput/CheckBoxInput";
import InputWithTitle from "../../../components/InputWithTitle/InputWithTitle";
import SelectFormField from "../../../components/select/SelectFormField";
import { notMathChar, onlyNumbers } from "../../../utils/normalize";
import { SHIPPING_SETTINGS_IDS } from "../../../constants/analytics";
import {
  getProductOptions,
  getServiceSelectDropdownValues,
} from "../../../features/config/config.selectors";

const DomesticShippingSettings = ({ onInputChange }) => {
  const productValues = useSelector(getProductOptions);
  const serviceValues = useSelector(getServiceSelectDropdownValues);
  return (
    <>
      <SubTitle title={DOMESTIC_SHIPPING_DEFAULTS} />
      <Field
        component={SelectFormField}
        label={PRODUCT}
        name={DOM_PRODUCT_FIELD}
        values={productValues}
        placeholder={PLEASE_SELECT}
        onChange={() => {
          onInputChange(DOM_PRODUCT_FIELD);
        }}
      />
      <Field
        component={SelectFormField}
        label={SERVICE}
        name={DOM_SERVICE_FIELD}
        values={serviceValues}
        placeholder={PLEASE_SELECT}
        onChange={() => {
          onInputChange(DOM_SERVICE_FIELD);
        }}
      />
      <Field
        name={DOM_NUM_ITEMS_FIELD}
        component={InputWithTitle}
        label={NUMBER_OF_ITEMS}
        maxLength={10}
        normalize={onlyNumbers}
      />
      <Field
        name={DOM_WEIGHT_FIELD}
        component={InputWithTitle}
        label={WEIGHT_KG}
        maxLength={10}
        normalize={notMathChar}
      />
      <Field
        name={DOM_ADDRESS_ON_LABEL_FIELD}
        component={CheckBoxInput}
        label={SENDERS_ADDRESS_ON_LABEL}
        onChange={() => {
          onInputChange(DOM_ADDRESS_ON_LABEL_FIELD);
        }}
      />
      <Field
        name={DOM_REFERENCE_TEXT_FIELD}
        component={InputWithTitle}
        label={REFERENCE_1_PREFIX}
        maxLength={50}
      />
      <Field
        name={BULK_REVERSE_REF_FIELD}
        component={InputWithTitle}
        label={DEFAULT_BULK_REVERSE_IT_REFERENCE}
        maxLength={12}
      />
    </>
  );
};

DomesticShippingSettings.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default compose(
  withTrackProps(() => ({
    onInputChange: type => {
      switch (type) {
        case DOM_PRODUCT_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRODUCT;
        case DOM_SERVICE_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_SERVICE;
        case DOM_ADDRESS_ON_LABEL_FIELD:
          return SHIPPING_SETTINGS_IDS.CLICK_CHECKBOX_SENDERS_ADDRESS_ON_LABEL;
      }
    },
  }))
)(DomesticShippingSettings);
