import { createSelector } from "reselect";

import {
  prepareProductOptions,
  prepareSelectCurrencyData,
  prepareSelectNetworkData,
  prepareServiceOptions,
} from "./config.models";
import { getBusinessId } from "../user/user.selectors";

export const getConfig = state => state.config;

export const getCurrenciesOptions = createSelector(getConfig, config =>
  prepareSelectCurrencyData(config.currency)
);

export const getNetworkOptions = createSelector(
  getConfig,
  getBusinessId,
  (config, businessId) => prepareSelectNetworkData(config.network, businessId)
);

export const getProductOptions = createSelector(
  getConfig,
  getBusinessId,
  (config, businessId) => prepareProductOptions(config.products, businessId)
);

export const getServiceSelectDropdownValues = createSelector(
  getConfig,
  getBusinessId,
  (config, businessId) => prepareServiceOptions(config.services, businessId)
);
