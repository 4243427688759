import sortBy from "lodash/sortBy";

import { GB, IE } from "./country.constants";
import { formatLabel } from "../../utils/string";

export const prepareSelectData = countries =>
  countries.map(country => ({
    label: formatLabel(country.description),
    value: country.key,
  }));

const isUKCountry = country => country.value === GB || country.value === IE;
const getStartListItems = countries =>
  sortBy(countries.filter(isUKCountry), country => country.value);
const getMainCountriesList = countries =>
  countries.filter(country => !isUKCountry(country));

export const prepareSortedCountriesList = countries => {
  const startItems = getStartListItems(countries);
  const countriesList = getMainCountriesList(countries);
  const sortedCountriesLIst = sortBy(countriesList, country => country.label);

  return [...startItems, ...sortedCountriesLIst];
};
