import { isDpd, isLocalDpd } from "../user/user.models";
import { formatLabel } from "../../utils/string";
import filter from "lodash/filter";

export const prepareSelectNetworkData = (networks, businessId) => {
  let filterdValues = [];
  if (isDpd(businessId)) {
    filterdValues = networks.filter(network => network.dpdGroup);
  }
  if (isLocalDpd(businessId)) {
    filterdValues = networks.filter(network => network.dpdLocal);
  }
  return filterdValues.map(network => ({
    label: formatLabel(network.networkDescription),
    value: network.networkCode,
  }));
};

export const prepareSelectCurrencyData = currencies => {
  const webEntryCurrencies = filter(
    currencies,
    currency => currency.webEntry
  ).map(currency => ({
    label: `${currency.currencyDescription} (${currency.currencyCode})`,
    value: currency.currencyCode,
  }));
  return webEntryCurrencies.sort((a, b) => {
    if (a.value === "GBP") return -1;
    if (b.value === "GBP") return 1;
    if (a.value === "EUR") return -1;
    if (b.value === "EUR") return 1;

    return a.label > b.label ? 1 : -1;
  });
};

export const prepareProductData = products =>
  products.map(
    ({
      productDescription: label,
      productCode: value,
      dpdGroup,
      dpdLocal,
    }) => ({ label: formatLabel(label), value, dpdGroup, dpdLocal })
  );

const filterByBusinessId = dataMapper => (data = [], businessId) => {
  const dpdType = isLocalDpd(businessId) ? "dpdLocal" : "dpdGroup";
  return dataMapper(data).filter(product => product[dpdType]);
};

export const prepareProductOptions = filterByBusinessId(prepareProductData);

export const prepareServiceData = service =>
  service.map(({ serviceDescription, serviceCode, dpdGroup, dpdLocal }) => ({
    label: formatLabel(serviceDescription),
    value: serviceCode,
    dpdGroup,
    dpdLocal,
  }));

export const prepareServiceOptions = filterByBusinessId(prepareServiceData);
