export const SHARE_PROFILE_FIELD = "shareProfile";
export const INT_COUNTRY_FIELD = "intCountry";
export const DEFAULT_SHIPMENT_DATE_FIELD = "defaultShipmentDate";
export const SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD = "shipmentAddressSearchType";
export const ENABLE_WEEKEND_FIELD = "enableWeekend";
export const DEFAULT_INFORMATION_1_FIELD = "defaultInformation1";
export const DEFAULT_INFORMATION_2_FIELD = "defaultInformation2";
export const DEFAULT_INFORMATION_3_FIELD = "defaultInformation3";
export const DEFAULT_INFORMATION_4_FIELD = "defaultInformation4";
export const DEFAULT_INFORMATION_5_FIELD = "defaultInformation5";
export const DOMESTIC_FIELD = "domestic";
export const INTERNATIONAL_FIELD = "international";
export const PRINT_ON_SCAN_FIELD = "printOnScan";
export const SHIPPING_DEFAULTS = "shippingDefaults";

export const DOM_PRODUCT_FIELD = "domProduct";
export const DOM_SERVICE_FIELD = "domService";
export const DOM_NUM_ITEMS_FIELD = "domNumItems";
export const DOM_WEIGHT_FIELD = "domWeight";
export const DOM_ADDRESS_ON_LABEL_FIELD = "domAddressOnLabel";
export const DOM_REFERENCE_TEXT_FIELD = "domReferenceText";
export const BULK_REVERSE_REF_FIELD = "bulkReverseRef";

export const INT_PRODUCT_FIELD = "intProduct";
export const INT_NUM_ITEMS_FIELD = "intNumItems";
export const INT_WEIGHT_FIELD = "intWeight";
export const INT_REFERENCE_TEXT_FIELD = "intReferenceText";
export const INT_LIABILITY_FIELD = "intLiability";
export const INT_LIABILITY_VALUE_FIELD = "intLiabilityValue";
export const INT_CURRENCY_FIELD = "intCurrency";
export const INT_CONTENT_DESCRIPTION_FIELD = "intContentDescription";
export const DIMENSION_FIELD = "dimension";

export const INT_DIM_WEIGHT_FIELD = "intDimWeight";
export const INT_DIM_WIDTH_FIELD = "intDimWidth";
export const INT_DIM_LENGTH_FIELD = "intDimLength";
export const INT_DIM_HEIGHT_FIELD = "intDimHeight";

export const GENERAL_FIELDS = [
  SHARE_PROFILE_FIELD,
  INT_COUNTRY_FIELD,
  DEFAULT_SHIPMENT_DATE_FIELD,
  SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD,
  ENABLE_WEEKEND_FIELD,
  PRINT_ON_SCAN_FIELD,
  DEFAULT_INFORMATION_1_FIELD,
  DEFAULT_INFORMATION_2_FIELD,
  DEFAULT_INFORMATION_3_FIELD,
  DEFAULT_INFORMATION_4_FIELD,
  DEFAULT_INFORMATION_5_FIELD,
];

export const DIMENSION_FIELDS = [
  INT_DIM_WEIGHT_FIELD,
  INT_DIM_WIDTH_FIELD,
  INT_DIM_LENGTH_FIELD,
  INT_DIM_HEIGHT_FIELD,
];

export const INTERNATIONAL_FIELDS = [
  INT_PRODUCT_FIELD,
  INT_NUM_ITEMS_FIELD,
  INT_WEIGHT_FIELD,
  INT_REFERENCE_TEXT_FIELD,
  INT_LIABILITY_FIELD,
  INT_LIABILITY_VALUE_FIELD,
  INT_CURRENCY_FIELD,
  INT_CONTENT_DESCRIPTION_FIELD,
  DIMENSION_FIELD,
];

export const DOMESTIC_FIELDS = [
  DOM_PRODUCT_FIELD,
  DOM_SERVICE_FIELD,
  DOM_NUM_ITEMS_FIELD,
  DOM_WEIGHT_FIELD,
  DOM_ADDRESS_ON_LABEL_FIELD,
  DOM_REFERENCE_TEXT_FIELD,
  BULK_REVERSE_REF_FIELD,
];

export const NUMBER_FIELDS = [
  DOM_NUM_ITEMS_FIELD,
  INT_DIM_WIDTH_FIELD,
  INT_DIM_LENGTH_FIELD,
  INT_DIM_HEIGHT_FIELD,
];

export const RADIO_FIELDS = [PRINT_ON_SCAN_FIELD];

export const BOOL_FIELDS = [
  SHARE_PROFILE_FIELD,
  ENABLE_WEEKEND_FIELD,
  DOM_ADDRESS_ON_LABEL_FIELD,
];

export const DATE_FIELDS = [DEFAULT_SHIPMENT_DATE_FIELD];

export const SHORT_NAME = "Short Name";
export const ORGANISATION_NAME = "Organisation Name";
export const POST_CODE = "Post Code";
export const YES = "Yes";
export const NO = "No";

export const ADDRESS_BOOK_OPTIONS = [
  { value: 1, label: SHORT_NAME },
  { value: 2, label: ORGANISATION_NAME },
  { value: 3, label: POST_CODE },
];

export const LIABILITY_OPTIONS = [
  { value: "Y", label: YES },
  { value: "N", label: NO },
];

export const DROPDOWN_FIELDS = [
  INT_COUNTRY_FIELD,
  SHIPMENT_ADDRESS_SEARCH_TYPE_FIELD,
  INT_PRODUCT_FIELD,
  INT_LIABILITY_FIELD,
  DOM_PRODUCT_FIELD,
  DOM_SERVICE_FIELD,
];

export const CHECKBOX_FIELD = [
  ENABLE_WEEKEND_FIELD,
  SHARE_PROFILE_FIELD,
  DOM_ADDRESS_ON_LABEL_FIELD,
];
