import { ACTIONS } from "./config.actions";

export const initialState = {
  dpdServices: [],
  dpdLocalServices: [],
  serviceRestrictions: {},
  serviceRestrictionHelper: {},
  currency: [],
  network: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_CONFIG.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
      };
    default:
      return state;
  }
};
