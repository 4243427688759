import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import SubTitle from "../../components/Title/SubTitle";
import Title from "../../components/Title/Title";
import InnerLayout from "../../components/Layout/InnerLayout";
import styles from "./CollectionDefaults.module.scss";
import { COLLECTION_DETAILS_FORM } from "../../constants/forms";
import {
  CLOSING_TIME,
  COLLECTION_DEFAULT_DETAILS,
  COLLECTION_DEFAULTS_MSG,
  COLLECTION_DETAILS,
  ERROR_SAVING_MSG,
  NUMBER_OF_ITEMS,
  PRODUCT,
  SERVICE,
  WEIGHT,
} from "../../constants/strings";
import {
  CLOSING_TIME_FIELD,
  DEFAULT_PRODUCT,
  DEFAULT_SERVICE_FIELD,
  DO_NOT_PROMPT_FOR_COLLECTION,
  NUMBER_OF_ITEMS_FIELD,
  PRE_ARRANGED,
  SELECT_PRODUCT,
  SELECT_SERVICE,
  WEIGHT_FIELD,
} from "../../features/collectionDefaults/collectionDefaults.constants";
import InputWithTitle from "../../components/InputWithTitle/InputWithTitle";
import CheckBoxInput from "../../components/CheckBoxInput/CheckBoxInput";
import FormBottomSection from "../../components/formBottomSection/FormBottomSection";
import SelectFormField from "../../components/select/SelectFormField";
import { notMathChar, onlyNumbers } from "../../utils/normalize";
import {
  initializeForm,
  updateCollectionDefaults,
} from "../../features/collectionDefaults/collectionDefaults.actions";
import { getUserErrorMessage } from "../../features/user/user.selectors";
import { validate } from "../../features/collectionDefaults/collectionDefaults.validators";
import {
  getProductOptions,
  getServiceSelectDropdownValues,
} from "../../features/config/config.selectors";
import { COLLECTION_DEFAULTS_IDS } from "../../constants/analytics";

const CollectionDefaults = ({
  handleSubmit,
  message,
  onSave,
  onInputChange,
  productValues,
  serviceValues,
}) => {
  if (message === ERROR_SAVING_MSG) {
    message = `${message} ${COLLECTION_DEFAULTS_MSG}`;
  }

  return (
    <div className={styles.container}>
      <Title title={COLLECTION_DETAILS} />
      <InnerLayout>
        <Field
          name={PRE_ARRANGED}
          component={CheckBoxInput}
          label={DO_NOT_PROMPT_FOR_COLLECTION}
        />
        <SubTitle title={COLLECTION_DEFAULT_DETAILS} />

        <Field
          component={SelectFormField}
          label={PRODUCT}
          name={DEFAULT_PRODUCT}
          values={productValues}
          placeholder={SELECT_PRODUCT}
          onChange={() => {
            onInputChange(DEFAULT_PRODUCT);
          }}
        />
        <Field
          component={SelectFormField}
          label={SERVICE}
          name={DEFAULT_SERVICE_FIELD}
          values={serviceValues}
          placeholder={SELECT_SERVICE}
          onChange={() => {
            onInputChange(DEFAULT_SERVICE_FIELD);
          }}
        />
        <Field
          name={NUMBER_OF_ITEMS_FIELD}
          component={InputWithTitle}
          label={NUMBER_OF_ITEMS}
          maxLength="10"
          classNameLabel={styles.label}
          classNameField={styles.field}
          normalize={onlyNumbers}
        />
        <Field
          name={WEIGHT_FIELD}
          component={InputWithTitle}
          label={`${WEIGHT} (Kg)`}
          maxLength="10"
          classNameLabel={styles.label}
          classNameField={styles.field}
          normalize={notMathChar}
        />
        <Field
          name={CLOSING_TIME_FIELD}
          component={InputWithTitle}
          label={`${CLOSING_TIME} (HH:MM)`}
          classNameLabel={styles.label}
          classNameField={styles.field}
        />
      </InnerLayout>
      <FormBottomSection onSave={handleSubmit(onSave)} message={message} />
    </div>
  );
};

CollectionDefaults.propTypes = {
  handleSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
  onSave: PropTypes.func,
  serviceValues: PropTypes.array,
  productValues: PropTypes.array,
};

const mapStateToProps = state => ({
  message: getUserErrorMessage(state),
  serviceValues: getServiceSelectDropdownValues(state),
  productValues: getProductOptions(state),
});

const mapDispatchToProps = dispatch => ({
  updateCollectionDefaults: values =>
    dispatch(updateCollectionDefaults(values)),
  onInitializeForm: () => dispatch(initializeForm()),
});

export default compose(
  reduxForm({
    form: COLLECTION_DETAILS_FORM,
    validate,
  }),
  withTrack({
    loadId: COLLECTION_DEFAULTS_IDS.LOAD,
    interfaceId: COLLECTION_DEFAULTS_IDS.INTERFACE_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(props => ({
    onSave: values => {
      props.updateCollectionDefaults(values);
      return COLLECTION_DEFAULTS_IDS.CLICK_SAVE;
    },
    onInputChange: type => {
      switch (type) {
        case DEFAULT_PRODUCT:
          return COLLECTION_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_PRODUCT;
        case DEFAULT_SERVICE_FIELD:
          return COLLECTION_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_SERVICE;
      }
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.onInitializeForm();
    },
  })
)(CollectionDefaults);
