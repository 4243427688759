export const ENVIRONMENTS = {
  PRODUCTION: "production",
  DEVELOPMENT: "development",
};
export const WEB_BASED_SYSTEM = "WBS";

export const API_ENDPOINTS = {
  AUTH: "/auth",
  AUDIT: "/audit",
  CONFIG: "/config",
  CUSTOMER: "/customer",
  ESG: "/esg",
  USER: "/user",
  PROFILE: "/profile",
  SEQUENCE: "/sequence",
};

export const dpdLocalDomain = "dpdlocal";
