import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as configService from "./config.service";

const ACTION_NAMESPACE = "CONFIG";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_CONFIG: createAsyncActionTypes("FETCH_CONFIG"),
});

export const fetchConfig = createAsyncAction(
  () => configService.fetchConfig(),
  ACTIONS.FETCH_CONFIG
);
